<template>
  <div class="my-3 mx-4">
    <div class="row">
      <div class="col-12">
        <div class="heading">Reservation Edit - Review Reservation</div>
        <br />
        <AdminReservationContact
          header="Reservation Customer"
          :contact="customer"
        />
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-2 mb-0">
                  Existing Reservation Information
                </h4>
              </div>
              <div class="card-body" v-if="existingReservation.spot">
                <div class="row">
                  <div class="col-4">
                    <span class="label">Park:</span>
                    {{ existingReservation.spot.location.longName }}
                  </div>
                  <div class="col-4">
                    <span class="label">Loop:</span>
                    {{ existingReservation.spot.loop.name }}
                  </div>
                  <div class="col-4">
                    <span class="label">Spot Name:</span>
                    {{ existingReservation.spot.name }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <span class="label">Spot Type(s):</span>
                    {{ spotTypes }}
                  </div>
                  <div class="col-4">
                    <span class="label">Number of Nights:</span>
                    {{ numberOfNights }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <span class="label">Arrival Date:</span>
                    {{ existingReservation.startDate }}
                  </div>
                  <div class="col-4">
                    <span class="label">Departure Date:</span>
                    {{ existingReservation.endDate }}
                  </div>
                  <div class="col-4">
                    <span class="label" v-if="!!existingReservation.firstName"
                      >Primary Occupant:</span
                    >
                    {{ existingReservation.firstName }}
                    {{ existingReservation.lastName }}
                  </div>
                </div>
                <div class="row" v-if="reservation.numberOfAdults">
                  <div class="col-4">
                    <span class="label">Number of People:</span>
                    {{ existingReservation.numberOfAdults }}
                  </div>
                  <div class="col-4">
                    <span class="label">Number of Vehicles:</span>
                    {{ existingReservation.numberOfVehicles }}
                  </div>
                </div>
                <div class="row" v-if="reservation.equipmentType">
                  <div class="col-4">
                    <span class="label">Equipment Type:</span>
                    {{ existingReservation.equipmentType }}
                  </div>
                  <div class="col-4">
                    <span class="label">Equipment Length (ft):</span>
                    {{ existingReservation.equipmentLength }}
                  </div>
                </div>
                <br />
                <div>
                  <div class="row">
                    <div
                      v-for="discount in discountClasses"
                      :key="discount.id"
                      class="col-4"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="discount.id"
                          :id="`discount_class_${discount.id}`"
                          v-model="existingReservation.discounts"
                          disabled
                        />
                        <label
                          class="form-check-label"
                          :for="`discount_class_${discount.id}`"
                          >{{ discount.name }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="card">
              <div class="card-header">
                <h2 class="card-title mt-2 mb-0">Cost</h2>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  v-for="(fee, index) in existingReservationFees"
                  :key="index"
                >
                  <div class="col-12">
                    <span class="label">{{ fee.name }}:</span>
                    <span class="float-right">{{
                      feeTotal(fee) | formatCurrency
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <AdminReservationInfo
              title="New Reservation Information"
              :showCheckboxes="true"
            />
          </div>
          <div class="col-md-4 col-sm-12">
            <ValidationObserver ref="reservationCost">
              <AdminReservationCost
                :fees="fees"
                :reservation="reservation"
                :discount="discountReceived"
                permission="ReservationManagementOverrideOnModify"
                :newReservation="false"
              />
            </ValidationObserver>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-3">
                <span class="label">Park Use Fee Paid:</span>
                <span class="float-right">{{
                  totalAmountPaid | formatCurrency
                }}</span>
              </div>
            </div>
            <div class="row" v-for="fee in fees" :key="fee.id">
              <div class="col-3">
                <span class="label">New {{ fee.name }}:</span>
                <span class="float-right">{{
                  reservation["fee" + fee.id] | formatCurrency
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <hr />
                <span class="label">
                  {{
                    totalAmountDue > totalAmountPaid
                      ? "Balance Due:"
                      : "Refund Due:"
                  }}
                </span>
                <span class="float-right">{{
                  editedTotal | formatCurrency
                }}</span>
              </div>
            </div>
            <div
              class="row"
              v-for="(refund, index) in refundBreakdown"
              :key="index"
            >
              <div class="col-3">
                <span class="label ml-3">
                  {{
                    `Refund to ${refund.paymentType.name}${
                      refund.lastFour ? " " + refund.lastFour : ""
                    }:`
                  }}
                </span>
                <span class="float-right">{{
                  refund.amount | formatCurrency
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3" v-if="refundingCash">
                <label for="verifyCashRefund" class="mr-2 float-left pt-1">
                  <input
                    type="checkbox"
                    :value="verifyCashRefund"
                    id="verifyCashRefund"
                    v-model="verifyCashRefund"
                  />
                  Verify Cash Amount</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row ml-1">
      <MoneyInput
        v-model="cashRecieved"
        id="cashRecieved"
        name="Cash Recieved"
        v-if="cashForPayment"
      />
      <div v-if="cashForPayment" class="mx-4">
        <label for="changeDue"> Change Due</label>
        <div class="change">{{ changeDue | formatCurrency }}</div>
      </div>
      <div class="mt-4">
        <button
          class="btn btn-lg btn-primary mr-2"
          type="button"
          @click="finish(creditCardType.paymentTypeId)"
          v-if="totalAmountDue > totalAmountPaid && !cashForPayment"
        >
          Pay by Credit/Debit card<sup>1</sup> -
          {{ creditCardAmount | formatCurrency }}
        </button>
        <button
          class="btn btn-lg btn-primary mr-2"
          type="button"
          @click="finish(eCheckType.paymentTypeId)"
          v-if="totalAmountDue > totalAmountPaid && !cashForPayment"
        >
          Pay by Echeck<sup>2</sup> - {{ eCheckAmount | formatCurrency }}
        </button>
        <button
          class="btn btn-lg btn-primary mr-2"
          type="button"
          @click="payByCash"
          v-if="totalAmountDue > totalAmountPaid && !cashForPayment"
        >
          Pay by Cash - {{ editedTotal | formatCurrency }}
        </button>
        <button
          class="btn btn-lg btn-primary mr-2"
          type="button"
          @click="finish(cashType.paymentTypeId)"
          :disabled="changeDue == null"
          v-if="totalAmountDue > totalAmountPaid && cashForPayment"
        >
          Confirm Cash Received
        </button>
        <button
          class="btn btn-lg btn-primary mr-2"
          type="button"
          @click="finish(null)"
          v-if="!(totalAmountDue > totalAmountPaid)"
          :disabled="refundingCash && !verifyCashRefund"
        >
          Confirm
        </button>
        <button
          type="button"
          @click="resetFees"
          class="btn btn-lg btn-secondary mr-2"
          :disabled="!checkPermission('ReservationManagementOverrideOnModify')"
        >
          Reset Fees
        </button>
        <button
          type="button"
          @click="goBack"
          class="btn btn-lg btn-secondary mr-2"
        >
          Back
        </button>
        <button
          type="button"
          @click="goHome"
          class="btn btn-cancel btn-lg mr-2"
        >
          Cancel
        </button>
      </div>
    </div>
    <br />
    <span class="fine-print" v-if="convenienceFee != {}">
      <sup>1</sup> CC Payment option includes
      {{ convenienceFee.creditCardPercentage }}% (minimum ${{
        convenienceFee.creditCardMinAmount
      }}) payment processing fee.
    </span>
    <span class="fine-print" v-if="convenienceFee != {}">
      <sup>2</sup> Echeck Payment option includes ${{
        convenienceFee.echeckAmount
      }}
      payment processing fee.
    </span>
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationInfo from "@/components/admin/AdminReservationInfo.vue";
import AdminReservationCost from "@/components/admin/AdminReservationCost.vue";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import LocationService from "@/services/LocationService.js";
import TenantService from "@/services/TenantService.js";
import { ValidationObserver } from "vee-validate";
import PricingService from "@/services/PricingService.js";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import moment from "moment";
import MoneyInput from "@/validation/MoneyInput.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminReservationEditReview",
  title: "Admin - Reservation Edit Review",
  mixins: [checkPermissionMixin],
  components: {
    AdminReservationContact,
    AdminReservationInfo,
    ValidationObserver,
    AdminReservationCost,
    MoneyInput
  },
  data() {
    return {
      customer: null,
      paymentTypes: [],
      convenienceFee: {},
      existingReservation: {},
      fees: [],
      errors: [],
      discountClasses: [],
      cashForPayment: false,
      cashRecieved: 0,
      refundBreakdown: [],
      verifyCashRefund: false
    };
  },
  methods: {
    goHome() {
      window.scrollTo(0, 0);
      this.$store.dispatch("transaction/clear");
      this.$router.push("/admin").catch(() => {});
    },
    goBack() {
      window.scrollTo(0, 0);
      this.$router.push("/admin/reservation-edit-occupant").catch(() => {});
    },
    payByCash() {
      this.cashForPayment = true;
    },
    async getPaymentTypes() {
      const service = new AdminLookupService(this.tenantId);
      const response = await service.getPaymentTypes();
      if (response?.statusCode === "Success") {
        this.paymentTypes = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    finish(paymentTypeId) {
      this.$refs.reservationCost.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.reservationCost.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.reservationCost.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          await this.onCheckout(paymentTypeId);
        }
      });
    },
    async onCheckout(paymentTypeId) {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const request = this.mapReservationToRequest(
        this.reservation,
        this.existingReservation.id
      );
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          tenantId
        );
        let response = await spotReservationService.modifyReservation(
          this.reservation.id,
          request,
          paymentTypeId
        );

        if (response && response.statusCode === "Success") {
          if (response.ccpRedirectUrl) {
            window.location = response.ccpRedirectUrl;
          } else {
            this.$router
              .push(
                "/admin/reservation-edit-receipt/?orderId=" + response.orderId
              )
              .catch(() => {});
          }
        } else if (response && response.statusCode === "Failure") {
          let errorMessage = "Error during checkout.";
          if (response.messages && response.messages.length > 0) {
            errorMessage = response.messages[0];
          }
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: errorMessage,
            layer: "admin"
          });
        } else if (
          response &&
          response.statusCode === "Error" &&
          response.messages.length > 0 &&
          (response.messages[0].includes(
            "Requested refund amount greater then remaining amount available"
          ) ||
            response.messages[0].includes("There aren't enough park use fees"))
        ) {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Refunds failed, please contact system administrator",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    mapReservationToRequest(reservation, reservationId) {
      const feeOverrides = [];
      this.fees.forEach(fee => {
        let feeOverrideAmount = reservation["fee" + fee.id];
        if (+feeOverrideAmount != fee.amount * fee.quantity) {
          let feeOverride = { ...fee };
          feeOverride.amount = feeOverrideAmount;
          feeOverrides.push(feeOverride);
        } else {
          feeOverrides.push(fee);
        }
      });

      const tenantId = this.$store.getters["tenant/tenantId"];

      const {
        spotId,
        startDate,
        endDate,
        numberOfAdults,
        numberOfVehicles,
        equipmentLength,
        equipmentType,
        firstName,
        lastName,
        street1,
        street2,
        city,
        state,
        zipcode,
        email,
        phone,
        alternatePhone,
        lockCode,
        discounts,
        oldDiscounts,
        customerId,
        overrideBusinessRule
      } = reservation;
      const existingReservation = this.existingReservation;
      const spotEquipmentType = reservation.spot.spotEquipmentTypes.find(
        x => x.equipmentType.name === equipmentType
      );
      const request = {
        newReservationRequest: {
          oldSpotId: existingReservation.spotId,
          oldStartDate: existingReservation.startDate,
          oldEndDate: existingReservation.endDate,
          tenantId,
          customerId,
          spotId,
          startDate,
          endDate,
          numberOfAdults,
          numberOfVehicles,
          previousNumberOfAdults: existingReservation.numberOfAdults,
          previousNumberOfVehicles: existingReservation.numberOfVehicles,
          discounts,
          oldDiscounts,
          equipmentLength: +equipmentLength,
          equipmentTypeId: spotEquipmentType.equipmentType.id,
          isCustomerPrimaryOccupant: true,
          primaryOccupantFirstName: firstName,
          primaryOccupantLastName: lastName,
          primaryOccupantStreet1: street1,
          primaryOccupantStreet2: street2,
          primaryOccupantCity: city,
          primaryOccupantState: state,
          primaryOccupantZipcode: zipcode,
          primaryOccupantCountry: "USA",
          primaryOccupantEmail: email,
          primaryOccupantHomePhone: alternatePhone,
          primaryOccupantMobilePhone: phone,
          lockCode,
          reservationId,
          overrideBusinessRule
        },
        feeOverrides: feeOverrides,
        reservationId: reservationId,
        customerId: customerId
      };
      return request;
    },
    async getCustomer() {
      const profileService = new AdminProfileService(this.tenantId);
      const customerResponse = await profileService.getCustomerByIdAdmin(
        this.reservation.customerId
      );
      const customer = customerResponse.data;
      const primaryPhone = customer.customerPhoneNumbers.find(x => x.isPrimary);
      this.customer = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        street1: customer.addresses[0].street1,
        street2: customer.addresses[0].street2,
        city: customer.addresses[0].city,
        state: customer.addresses[0].state,
        zipcode: customer.addresses[0].zipcode,
        mobilePhone: primaryPhone
          ? primaryPhone.number
          : customer.customerPhoneNumbers[0].number,
        email: customer.email,
        comments: customer.comments,
        username: customer.user?.username
      };
    },
    async getExistingReservationDetail() {
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const response = await spotReservationService.getReservationDetail(
        this.reservation.reservationId
      );
      const reservation = response.data;
      this.existingReservation = {
        ...reservation,
        firstName: reservation.primaryOccupant.firstName,
        lastName: reservation.primaryOccupant.lastName,
        startDate: reservation.arrivalDate,
        endDate: reservation.departureDate,
        discounts: reservation.discounts.map(x => x.discountClassId)
      };
      this.getSpotDetails();
      this.getFees();
    },
    async getSpotDetails() {
      const locationService = new LocationService(this.tenantId, null);
      const response = await locationService.getSpotDetails(
        this.existingReservation.spotId
      );
      this.existingReservation = {
        ...this.existingReservation,
        spot: response.spotDetails
      };
    },
    resetFees() {
      this.fees.forEach(fee => {
        let feeTotal = fee.amount * fee.quantity;
        this.$set(this.reservation, "fee" + fee.id, feeTotal.toFixed(2));
      });
      this.errors = [];
    },
    async getFees() {
      const reservation = this.reservation;
      try {
        const pricingService = new PricingService(this.tenantId);
        const {
          spotId,
          startDate,
          endDate,
          numberOfAdults,
          numberOfVehicles,
          discounts,
          oldDiscounts
        } = reservation;
        const existingReservation = this.existingReservation;
        const feeResponse = await pricingService.getFees(
          {
            oldStartDate: existingReservation.startDate,
            oldEndDate: existingReservation.endDate,
            oldSpotId: existingReservation.spotId,
            oldNumberOfAdults: existingReservation.numberOfAdults,
            customerId: existingReservation.customerId,
            spotId,
            startDate,
            endDate,
            numberOfAdults,
            numberOfVehicles,
            salesChannel: 2,
            discounts,
            oldDiscounts
          },
          2
        );
        if (feeResponse && feeResponse.statusCode === "Success") {
          const fees = [
            ...feeResponse.data.filter(x => x.feeType !== "Convenience")
          ];
          this.convenienceFee = feeResponse.data.find(
            x => x.feeType === "Convenience"
          );
          this.fees = fees;
          this.resetFees();
          await this.getRefundBreakdown();
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Fees weren't found",
            layer: "admin"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    feeTotal(fee) {
      if (fee) {
        if (fee.amount === 0) return 0;
        else return fee.amount * fee.quantity;
      } else {
        return 0;
      }
    },
    async getDiscounts() {
      const tenantService = new TenantService(this.tenantId);
      const response = await tenantService.getDiscountClasses();
      this.discountClasses = response.data;
    },
    async getRefundBreakdown() {
      const feeOverrides = [];
      this.fees.forEach(fee => {
        let feeOverrideAmount = this.reservation["fee" + fee.id];
        if (+feeOverrideAmount != fee.amount * fee.quantity) {
          let feeOverride = { ...fee };
          feeOverride.amount = feeOverrideAmount;
          feeOverrides.push(feeOverride);
        } else {
          feeOverrides.push(fee);
        }
      });
      const reservationService = new AdminSpotReservationService(this.tenantId);
      const response = await reservationService.getModificationRefundBreakdown(
        feeOverrides,
        this.existingReservation.id
      );
      this.refundBreakdown = response.data;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      return this.$store.getters["transaction/reservation"];
    },
    isDayUse() {
      if (
        !this.existingReservation.spot ||
        !this.existingReservation.spot.product
      )
        return null;

      return (
        this.existingReservation.spot.product.productType.bookingType.id === 2 //Day Use
      );
    },
    discountReceived() {
      let initialTotal = 0;
      if (this.fees) {
        this.fees.forEach(fee => {
          initialTotal += fee.amount * fee.quantity;
        });
        let discount = initialTotal - this.totalAmountDue;
        return discount > 0 ? discount.toFixed(2) : "0.00";
      } else {
        return "0.00";
      }
    },
    numberOfNights() {
      return moment(this.existingReservation.endDate, "MM/DD/YYYY").diff(
        moment(this.existingReservation.startDate, "MM/DD/YYYY"),
        "days"
      );
    },
    spotTypes() {
      let spotTypes = "";
      if (!this.existingReservation.spot) {
        return spotTypes;
      }
      let spotTypeList = this.existingReservation.spot.spotSpotTypes;
      spotTypeList = spotTypeList.sort((a, b) =>
        a.spotType.name.trim() > b.spotType.name.trim()
          ? 1
          : a.spotType.name.trim() < b.spotType.name.trim()
          ? -1
          : 0
      );
      spotTypeList.forEach((x, i) => {
        if (i + 1 === this.existingReservation.spot.spotSpotTypes.length) {
          spotTypes += x.spotType.name;
        } else {
          spotTypes += x.spotType.name + ", ";
        }
      });
      return spotTypes;
    },
    totalAmountPaid() {
      if (this.existingReservation?.reservationFees) {
        return (
          this.existingReservation.reservationFees[0].amount *
          this.existingReservation.reservationFees[0].quantity
        );
      } else {
        return 0;
      }
    },
    totalAmountDue() {
      return this.fees.reduce((accumulator, fee) => {
        if (
          this.reservation["fee" + fee.id] != "" &&
          !isNaN(this.reservation["fee" + fee.id])
        ) {
          return accumulator + Number(this.reservation["fee" + fee.id]);
        }
        return accumulator;
      }, 0);
    },
    editedTotal() {
      if (this.totalAmountPaid > this.totalAmountDue) {
        return this.totalAmountPaid - this.totalAmountDue;
      } else {
        return this.totalAmountDue - this.totalAmountPaid;
      }
    },
    existingReservationFees() {
      const latestDate = this.existingReservation?.reservationFees?.reduce(
        (a, b) => (a.dateCreated > b.dateCreated ? a : b)
      ).dateCreated;

      const latestOrderId = this.existingReservation?.reservationFees?.find(
        fee => fee.dateCreated == latestDate
      ).orderId;

      return this.existingReservation?.reservationFees?.filter(
        fee => fee.feeType === "ParkUse" || fee.orderId === latestOrderId
      );
    },
    creditCardAmount() {
      return this.convenienceFee?.creditCardAmount + this.editedTotal;
    },
    eCheckAmount() {
      return this.convenienceFee?.echeckAmount + this.editedTotal;
    },
    creditCardType() {
      return this.paymentTypes.find(x => x.paymentType === "CreditCard");
    },
    eCheckType() {
      return this.paymentTypes.find(x => x.paymentType === "Echeck");
    },
    cashType() {
      return this.paymentTypes.find(x => x.paymentType === "Cash");
    },
    changeDue() {
      if (this.cashRecieved == null || this.cashRecieved < this.editedTotal) {
        return null;
      }
      return this.cashRecieved - this.editedTotal;
    },
    refundingCash() {
      return this.refundBreakdown.some(x => x.paymentType.name == "Cash");
    }
  },
  watch: {
    totalAmountDue() {
      if (this.refundBreakdown.length > 0) {
        this.getRefundBreakdown();
      }
    }
  },
  created() {
    this.getCustomer();
    this.getDiscounts();
    this.getExistingReservationDetail();
    this.getPaymentTypes();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.summary {
  min-height: 510px;
}
.fine-print {
  font-size: smaller;
}
.change {
  font-weight: bold;
  font-size: 2em;
}
</style>
